import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpErrorResponse,
  HttpEventType
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {isArray, isObject} from 'util';

const config = environment.apiConfig;

@Injectable({providedIn: 'root'})
export class ApiService {
  constructor(private httpClient: HttpClient) {
  }

  dataTableToApiato(params) {
    const p = {} as any;

	if (params.include) {
		p.include = params.include;
	}
    // Limit parameter
    if (params.rows) {
      p.limit = params.rows;

      if (params.first) {
        p.page = 1 + params.first / params.rows;
      }
    }

    // Page parameter
    if (params.first) {
      p.page = 1 + params.first / params.rows;
    }

    // Sort parameters
    if (params.sortField) {
      p.orderBy = params.sortField;
      p.sortedBy = params.sortOrder === -1 ? 'DESC' : 'ASC';

    }

    let search = [];

    if (params.codes_email) p.name = params.codes_email
    if (params.codes_win) p.win = params.codes_win
    if (params.receipt_number) p.receipt_number = params.receipt_number
    if (params.receipt_value) p.receipt_value = params.receipt_value
    if (params.receipt_date) p.receipt_date = params.receipt_date

    if (params.codes_code) {
      p.code = params.codes_code
    } else {

      if (params.p_order_id) {
        p.order_id = params.p_order_id
      }

      for (const col in params.filters) {
        if (params.filters.hasOwnProperty(col) && params.filters[col].matchMode === 'contains') {
          search.push(col + ':' + params.filters[col].value);
		  if(col=='real_id') search.push('foreign_user_id:' + params.filters[col].value);		  
        }
        if (col=='cu.name') p.customer_name=params.filters[col].value;
        if (col=='prize_title') p.prize_title=params.filters[col].value;
        if (col=='catalog_name') p.catalog_name=params.filters[col].value;
        if (col=='status') p.status=params.filters[col].value;
        if (col=='partner_name') p.partner_name=params.filters[col].value;
        if (col=='title') p.text=params.filters[col].value;
        if (col=='real_id') p.real_id=params.filters[col].value;
        if (col=='created_at') p.created_at=params.filters[col].value;
        if (col=='email') p.email=params.filters[col].value;
        if (col=='surname') p.surname=params.filters[col].value;

      }
    }

    if (search.length) {
      p.search = search.join(";");

    }
    
    return p;
  }

  private transformJsonApiData(res: any) {
    const enritchData = (element, included) => {
      if (element.relationships) {
        for (let r in element.relationships) {
          element['attributes'][r] = [];
          if (
            element.relationships[r] &&
            element.relationships[r].data
          ) {
            if (element.relationships[r].data instanceof Array) {
              for (let rd of element.relationships[r].data) {
                if (included[rd.type][rd.id])
                  element['attributes'][r].push(
                    included[rd.type][rd.id]
                  );
              }
            } else {
              let rd = element.relationships[r].data;
              if (included[rd.type][rd.id])
                element['attributes'][r] = included[rd.type][rd.id];
            }
          }
        }
      }
      delete element.relationships;

      return {id: element.id, ...element.attributes};

    };

    
    let source = res;

    if (source.data) {
      let included = [];

      if (source.included && source.included.length) {
        for (let i of source.included) {
          if (!included[i.type]) {
            included[i.type] = {};
          }

          included[i.type][i.id] = {id: i.id, ...i.attributes};

        }
      }

      if (isArray(source.data)) {
        source.data.forEach((el, index) => {
          source.data[index] = enritchData(el, included);

        });

      } else {
        source.data = enritchData(source.data, included);

      }
      delete source.included;

    } else {
      return 'ok';

    }
    return source || {};
  }

  getAll(resource, params) {
     let encodedEmail = ''
   
     if(params && params.filters && params.filters.email) {
        if(params.filters.email.value.includes('+')){
          encodedEmail = '?email=' + encodeURIComponent(params.filters.email.value)
          delete params.filters.email
        }
    }

    return this.httpClient
      .get<any[]>(`${config.apiUrl}/${resource}${encodedEmail}`, {
        params: this.dataTableToApiato(params)
      })
      .pipe(map(this.transformJsonApiData));
  }


  search(resource, field, value) {
    return this.httpClient
      .get<any[]>(`${config.apiUrl}/${resource}`, {
        params: {search: field + ':' + value}
      })
      .pipe(map(this.transformJsonApiData));
  }

  getPermission() {
    return this.httpClient.get<any>(config.apiUrl + '/permissions_user');
  }

  getOneById(resource, id) {
    return this.httpClient
      .get<any>(`${config.apiUrl}/${resource}/${id}`, {})
      .pipe(map(this.transformJsonApiData));
  }

  actionById(resource, id, action) {
    return this.httpClient.get<any>(
      `${config.apiUrl}/${resource}/${id}/${action}`,
      {}
    );
  }

  uploadPicture(fileToUpload: File, resource: string) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, name);

    //this.headers.set('Content-Type', 'application/json' );
    //this.headers.set('Access-Control-Allow-Origin', '*' );

    return this.httpClient
      .post<any>(`${config.apiUrl}/${resource}`, formData)
      .pipe(
        map(event => this.getEventMessage(event, formData)),
        catchError(this.handleError)
      );
  }

  upload(resource, data) {
    return this.httpClient
      .post<any>(`${config.apiUrl}/${resource}`, data, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map(event => this.getEventMessage(event, data)),
        catchError(this.handleError)
      );
  }

  downloadFile(resource, id, action): Observable<Blob> {
    return this.httpClient.get<Blob>(
      `${config.apiUrl}/${resource}/${id}/${action}`,
      {responseType: 'blob' as 'json'}
    );
  }

  deleteById(resource, id) {
    return this.httpClient.delete(`${config.apiUrl}/${resource}/${id}`);
  }

  download(resource,id,action) {
    return this.httpClient
      .post<any>(`${config.apiUrl}/${resource}/${id}/${action}`,[]);
  }

  create(resource, data) {
    return this.httpClient
      .post<any>(`${config.apiUrl}/${resource}`, data)
      .pipe(map(this.transformJsonApiData));
  }

  downloadPdfTemplate(resource, data) {
    return this.httpClient
      .post<Blob>(`${config.apiUrl}/${resource}`, data, 
        {
          responseType: 'blob' as 'json'
        })
  }

  update(resource, data, id) {
    return this.httpClient
      .patch<any>(`${config.apiUrl}/${resource}/${id}`, data)
      .pipe(map(this.transformJsonApiData));
  }

  getServerErrorMessage(error) {
    console.log('apiService error', error); // error message

    return {
      severity: 'error',
      summary: 'Error',
      detail: error && error.message ? error.message : 'Invalid server response'
    };
  }

  private getEventMessage(event: HttpEvent<any>, formData) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
      case HttpEventType.Response:
        return event;
      default:
        return `File upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round((100 * event.loaded) / event.total);
    return {status: 'progress', message: percentDone};
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError('Something bad happened. Please try again later.');
  }
}

import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';


/*
    Crasta: prefix must be used in each localstorage p-table save state definition

*/
const localStoragePrefix = 'p-table-filter-';

@Component({
    templateUrl: './admin.component.html'
})
export class AdminComponent  {
    lastSection = "";

    constructor(
        router: Router
    ) {
        router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                let urlDeep = event.url.split('/');

                if (urlDeep.length > 2) {
                    this.cleanFilters(urlDeep[2]);
                }
            }
        });
    }

    cleanFilters(section) {
        // if you want to not delete state on refresh just change if condition to (this.lastSection.length && this.lastSection !== section)
        if (!this.lastSection.length || this.lastSection !== section) {
            this.lastSection = section;
            let keys = Object.keys(localStorage)

            keys.forEach((key) => {
                if (key.indexOf(localStoragePrefix + section) === 0) {
                    localStorage.removeItem(key);

                }
            })

        }
    }
}

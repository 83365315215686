import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AuthenticationService} from '../_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentJwt = this.authenticationService.currentJwtValue;
    if (currentJwt && currentJwt.access_token) {
      if (typeof request.body == 'object')
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${currentJwt.access_token}`,
            'Accept': `application/json`
          }
        });
      else
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${currentJwt.access_token}`,
            'Content-Type': `application/json`,
            'Accept': `application/json`
          }
        });
    }

    return next.handle(request);
  }
}

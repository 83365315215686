import {Injectable} from '@angular/core';
import {isDate, isObject, isArray} from 'util';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class FormService {
  moment = moment;

  constructor() {
  }

  setFormErrors(form, errors) {
    const errorFieldPrefix = 'data.attributes.';

    for (const field in form) {
      if (form.hasOwnProperty(field) && errors.hasOwnProperty(errorFieldPrefix + field)) {
        form[field].setErrors({'incorrect': true});

      }
    }
  }

  checkUpdates(form, data) {
    for (const field in form) {
      if (form.hasOwnProperty(field)) {
        if (!data.hasOwnProperty(field) || (!isObject(form[field].value) && form[field].value !== data[field]) ||
          (isObject(form[field].value) && form[field].value.id !== data[field])) {
          console.log('Found different field:', field, form[field].value, data[field]);
          return true;
        }
      }
    }

    return false;
  }

  formDataToObject(formData, useAttributes = true) {
    const params = {};

    for (const field in formData) {
      if (formData.hasOwnProperty(field)) {
        if (isDate(formData[field].value)) {
          params[field] = formData[field].value;

        } else if (isObject(formData[field].value) && !isArray(formData[field].value)) {
          params[field] = formData[field].value['id'];

        } else if (formData[field].value !== null) {
          params[field] = formData[field].value;

        }
      }
    }

    if (useAttributes) {
      return this.getAttributeObject(params);
    }

    return params;

  }

  diffFormDataToObject(formData, resoruce, useAttributes = true) {
    const params = {};

    for (const field in formData) {
      if (formData.hasOwnProperty(field)) {
        if (isDate(formData[field].value)) {
          if (moment(formData[field].value).format('YYYY-MM-DD') !== resoruce[field]) {
            params[field] = formData[field].value;
          }

        } else if (isObject(formData[field].value) && !isArray(formData[field].value)) {
          if (formData[field].value['id'] !== resoruce[field]) {
            params[field] = formData[field].value['id'];
          }

        } else if (formData[field].value !== resoruce[field] && (formData[field].value || resoruce[field])) {
          params[field] = formData[field].value;

        }
      }
    }

    if (useAttributes) {
      return this.getAttributeObject(params);
    }

    return params;

  }

  getAttributeObject(params) {
    return {
      data: {
        attributes: params
      }
    };
  }

  getNoUpdateMessage() {
    return {
      severity: 'info',
      summary: 'Message',
      detail: 'No update found'
    };
  }
}

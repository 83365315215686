import {Component, Input, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {MenuItem} from 'primeng/api';
import {AppComponent} from './app.component';
import {PermissionService} from './permission/permission.service';

@Component({
  selector: 'app-menu',
  template: `
    <ul app-submenu [item]="app.grouped ? modelGrouped : modelUngrouped" root="true" class="layout-menu"
        visible="true" [reset]="reset" parentActive="true"></ul>
  `
})
export class AppMenuComponent implements OnInit, AfterViewInit {

  @Input() reset: boolean;

  modelGrouped: any[];

  modelUngrouped: any[];

  constructor(
    public app: AppComponent,
    private permissionService: PermissionService
  ) {
  }

  ngOnInit() {
    this.modelGrouped = [];
	this.permissionService.loadPermissions()
		.subscribe(permissions => {
			this.buildMenu()
		});
  }

  buildMenu() {
	// Custom
    if (this.permissionService.hasPermission('list-partner')) {
	  this.modelGrouped.push({
        label: 'Partners', icon: 'pi pi-fw pi-user', routerLink: ['/admin/partners']
      });
    }

    if (this.permissionService.hasPermission('list-users')) {
      this.modelGrouped.push({
        label: 'Users', icon: 'pi pi-fw pi-user', routerLink: ['/admin/users']
      });
    } 

    if (this.permissionService.hasPermission('list-prize')) {
      this.modelGrouped.push({
        label: 'Prizes', icon: 'pi pi-fw pi-briefcase', routerLink: ['/admin/prizes']
      });
    }

    if (this.permissionService.hasPermission('list-child')) {
      this.modelGrouped.push({
        label: 'Clients', icon: 'pi pi-fw pi-user', routerLink: ['/admin/clients']
      });
    }

    if (this.permissionService.hasPermission('list-catalog')) {
      this.modelGrouped.push({
        label: 'Catalogs', icon: 'pi pi-fw pi-list', routerLink: ['/admin/catalogs']
      });
    }

    //TODO: ask for import permission
    if (this.permissionService.hasPermission('list-order')) {
      const orderMenu = {
        label: 'Order', icon: 'pi pi-fw pi-shopping-cart', items: []
      };

      orderMenu.items.push({
        label: 'List', icon: 'pi pi-fw pi-shopping-cart', routerLink: ['/admin/orders/list']
      });
	  
	  if (this.permissionService.hasPermission('upload-order'))

      orderMenu.items.push({
        label: 'Upload', icon: 'pi pi-fw pi-upload', routerLink: ['/admin/orders/upload']
      });

      this.modelGrouped.push(orderMenu);
    }
    
    if (this.permissionService.hasPermission('list-item')) {
    this.modelGrouped.push({
      label: 'Coupons', icon: 'pi pi-fw pi-tags', routerLink: ['/admin/coupons']
    });
	}

    /*
    this.modelGrouped.push({
      label: 'Codes', icon: 'pi pi-fw pi-tags', routerLink: ['/admin/codes']
    });
    */

    if (this.permissionService.hasPermission('list-store')) {
      this.modelGrouped.push({
        label: 'Stores', icon: 'pi pi-fw pi-home', routerLink: ['/admin/stores']
      });
    }

    if (this.permissionService.hasPermission('list-category') || this.permissionService.hasPermission('list-provinces')) {
      const configMenu = {
        label: 'Config', icon: 'pi pi-fw pi-cog', items: []
      };

      if (this.permissionService.hasPermission('list-category')) {
        configMenu.items.push({
          label: 'Categories', icon: 'pi pi-fw pi-cog', routerLink: ['/admin/config/categories']
        });
      }

      if (this.permissionService.hasPermission('list-provinces')) {
        configMenu.items.push({
          label: 'Provinces', icon: 'pi pi-fw pi-cog', routerLink: ['/admin/config/provinces']
        });
      }

      configMenu.items.push({
        label: 'Countries', icon: 'pi pi-fw pi-cog', routerLink: ['/admin/config/countries']
      });

      this.modelGrouped.push(configMenu);
    }

    this.modelUngrouped = [
      {
        label: '',
        icon: 'pi pi-fw pi-home',
        items: this.modelGrouped
      }
    ];
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.app.layoutMenuScrollerViewChild.moveBar();
    }, 100);
  }

  changeTheme(theme: string, scheme: string) {
    const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';

    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    themeLink.href = 'assets/theme/' + theme + '/theme-' + scheme + '.css';

    const topbarLogo: HTMLImageElement = document.getElementById('layout-topbar-logo') as HTMLImageElement;

    const menuLogo: HTMLImageElement = document.getElementById('layout-menu-logo') as HTMLImageElement;

    if (theme === 'yellow' || theme === 'lime') {
      topbarLogo.src = 'assets/myprize/myprize-black.png';
      menuLogo.src = 'assets/myprize/myprize-black.png';
    } else {
      topbarLogo.src = 'assets/myprize/myprize-white.png';
      menuLogo.src = 'assets/myprize/myprize-white.png';
    }

    if (scheme === 'dark') {
      this.app.darkMenu = true;
    } else if (scheme === 'light') {
      this.app.darkMenu = false;
    }
  }
}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
    <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
      <li [ngClass]="{'layout-root-menuitem':root ,'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass"
          *ngIf="child.visible === false ? false : true">
        <div *ngIf="root">
          <span class="layout-menuitem-text">{{child.label}}</span>
        </div>
        <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
           *ngIf="!child.routerLink" [class]="child.styleClass"
           [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
          <i class="layout-menuitem-icon" [ngClass]="child.icon"></i>
          <span class="layout-menuitem-text">{{child.label}}</span>
          <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
          <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>

        <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" *ngIf="child.routerLink"
           [routerLink]="child.routerLink" routerLinkActive="active-route" [fragment]="child.fragment"
           [routerLinkActiveOptions]="{exact: false}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
          <i class="layout-menuitem-icon" [ngClass]="child.icon"></i>
          <span class="layout-menuitem-text">{{child.label}}</span>
          <i class="pi pi-fw pi-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
          <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>
        <div class="layout-menu-tooltip">
          <div class="layout-menu-tooltip-arrow"></div>
          <div class="layout-menu-tooltip-text">{{child.label}}</div>
        </div>
        <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)"
            [reset]="reset" [parentActive]="isActive(i)"
            [@children]="(app.isSlim()||app.isHorizontal())&&!app.isMobile()&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' :
                    app.grouped===true && root? 'visibleAnimated': 'hiddenAnimated'"></ul>
      </li>
    </ng-template>
  `,
  animations: [
    trigger('children', [
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*',
        'z-index': 100
      })),
      state('hidden', style({
        height: '0px',
        'z-index': '*'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppSubMenuComponent {

  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _parentActive: boolean;

  _reset: boolean;

  activeIndex: number;

  constructor(public app: AppComponent, public appMenu: AppMenuComponent) {
  }

  itemClick(event: Event, item: MenuItem, index: number) {
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = (this.activeIndex === index) ? null : index;

    // execute command
    if (item.command) {
      item.command({originalEvent: event, item});
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      setTimeout(() => {
        this.app.layoutMenuScrollerViewChild.moveBar();
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.app.isHorizontal() || this.app.isSlim()) {
        this.app.resetMenu = true;
      } else {
        this.app.resetMenu = false;
      }

      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  onMouseEnter(index: number) {
    if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())
      && !this.app.isMobile() && !this.app.isTablet()) {
      this.activeIndex = index;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = null;
    }
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { shareReplay, takeUntil } from "rxjs/operators";

import { environment } from '../../environments/environment';
import { Permission } from './permission';

const config = environment.apiConfig;

@Injectable({ providedIn: 'root' })
export class PermissionService {
    public currentPermissions: Permission[];

    constructor(private http: HttpClient) {
    }

    /*
    public get currentPermissionsArray(): any {
      return this.currentPermissions;
    }
    */

    loadPermissions() {

        return this.http.get<any[]>(`${config.apiUrl}/permissions_user`)
            .pipe(map(permissions => {

                this.currentPermissions = permissions['data'];

                console.log(this.currentPermissions)

                return permissions;

            }));
    }

    getAllRoles() {
        return this.http.get<any>(`${config.apiUrl}/roles`);
    }

    hasPermission(name) {
		if (this.currentPermissions) {
            return this.currentPermissions ?
                this.currentPermissions.find(permission => permission.attributes.name === name) : false;
        } else {
            return this.loadPermissions()
                .subscribe(permissions => {
                    this.hasPermission(name)
                });
        }
    }
}

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DtService {
    constructor() {
    }

    hasFilters(event) {
        if (event.hasOwnProperty('sortField') && event['sortField']) {
            return true;
        }

        if (event.hasOwnProperty('filters') && Object.keys(event['filters']).length) {
            return true;
        }

        return false;
    }
}

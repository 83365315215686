import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserLoginComponent } from './+login/login.component';
import { AdminComponent} from './admin/admin.component';
import { AuthGuard } from './_guards';

const routes: Routes = [
  {
    path: 'login',
    component: UserLoginComponent
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        loadChildren: () => import('./admin/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'partners',
        loadChildren: () => import('./admin/partner/partner.module').then(m => m.PartnerModule)
      },
      {
        path: 'prizes',
        loadChildren: () => import('./admin/prize/prize.module').then(m => m.PrizeModule)
      },
      {
        path: 'clients',
        loadChildren: () => import('./admin/client/client.module').then(m => m.ClientModule)
      },
      {
        path: 'catalogs',
        loadChildren: () => import('./admin/catalog/catalog.module').then(m => m.CatalogModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('./admin/order/order.module').then(m => m.OrderModule)
      },
      {
        path: 'coupons',
        loadChildren: () => import('./admin/coupon/coupon.module').then(m => m.CouponModule)
      },
      {
        path: 'codes',
        loadChildren: () => import('./admin/code/code.module').then(m => m.CodeModule)
      },
      {
        path: 'stores',
        loadChildren: () => import('./admin/store/store.module').then(m => m.StoreModule)
      },
      {
        path: 'config',
        loadChildren: () => import('./admin/config/config.module').then(m => m.ConfigModule)
      }
    ]
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

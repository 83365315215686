import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from './_services';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-bootstrap',
  templateUrl: './app.bootstrap.component.html'
})
export class AppBootstrapComponent {
  constructor(
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../environments/environment';

const config = environment.apiConfig;

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentJwtSubject: BehaviorSubject<any>;
  public currentJwt: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentJwtSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentJwt')));
    this.currentJwt = this.currentJwtSubject.asObservable();
  }

  public get currentJwtValue(): any {
    return this.currentJwtSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${config.apiUrl}/oauth/token`, {
      username: username,
      password: password,
      client_id: config.client_id,
      client_secret: config.client_secret,
      grant_type: config.grant_type,
      scope: config.scope
    })
      .pipe(map(jwt => {
        // login successful if there's a jwt token in the response
        if (jwt && jwt.access_token) {
          // store jwt details and jwt token in local storage to keep jwt logged in between page refreshes
          localStorage.setItem('currentJwt', JSON.stringify(jwt));
          this.currentJwtSubject.next(jwt);
        }

        return jwt;
      }));
  }

  logout() {
    // remove jwt from local storage to log jwt out
    localStorage.removeItem('currentJwt');
    this.currentJwtSubject.next(null);
  }
}
